var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn faster","leave-active-class":"animated fadeOut faster"}},[_c('div',{staticClass:"blocobmenu"},[_c('div',{staticClass:"containerReduced"},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInDown faster","leave-active-class":"animated slideOutUp faster"}},[_c('button',{staticClass:"closebutton",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('closeIcon',{attrs:{"rectBackgroundColor":"#FFFFFF","strokeColor":"#3E3E3E","bordercolor":"#D5D5D5"}})],1)]),_c('div',{staticClass:"row"},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeInLeft","leave-active-class":"animated fadeOutLeft"}},[_c('div',{staticClass:"col-12 col-md-5 d-none d-md-block space"})]),_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeInRight","leave-active-class":"animated fadeOutRight"}},[_c('nav',{staticClass:"col-12 col-md-4 blocoBSelectCategory"},[_c('ul',{key:"filterCategory",ref:"filterCategory",staticClass:"categoryMenu"},_vm._l((_vm.categories),function(category,i){return _c('li',{key:i,class:category.id == _vm.selectedCategory
				                      ? 'filters__item active'
				                      : 'filters__item'},[_c('router-link',{attrs:{"to":{
			                          path:
			                            '/bloco-b/category/' +
			                            category.id
			                        }}},[_vm._v(_vm._s(category.name))]),_c('ul',{key:"filterCollection",ref:"filterCollection",refInFor:true,class:'cat-' + _vm.selectedCategory + ' collectionMenu'},_vm._l((_vm.collections),function(collection,c){return _c('li',{key:c,staticClass:"filters__item",class:collection.id == _vm.selectedCollection
					                          ? 'filters__item active'
					                          : 'filters__item'},[_c('router-link',{attrs:{"to":{
					                          path:
					                            '/bloco-b/category/' +
					                            category.id +
					                            '/collection/' +
					                            collection.id +
					                            '/',
					                        }}},[_vm._v(_vm._s(collection.name))])],1)}),0)],1)}),0)])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }